<template>
  <b-card class="mobile-card">
    <b-container v-b-toggle="`mouvement_${item.idMouvement}`">
      <b-row align-v="center">
        <b-col cols="auto" class="bg-light bg-left font-up" style="width: 80px">
          <div class="w-100">
            {{ jour }}/{{ mois }}<br />
            {{ annee }}
          </div>
        </b-col>
        <b-col>
          <b-row class="m-0" align-v="center">
            <b-col class="p-0">
              {{ item.libelleTypeMouvement }}
              <div class="font-down">
                <MontantComponent
                  :value="item.montantTtc"
                  :options="{ style: 'currency', currency: 'EUR' }"
                  variantPositive="success"
                  variantNegative="danger"
                ></MontantComponent>
                <div v-if="item.solde !== 0">
                  <b-icon-x-circle-fill variant="danger">
                  </b-icon-x-circle-fill>
                  Non sold&eacute;
                </div>
              </div>
              <b-collapse
                :id="`mouvement_${item.idMouvement}`"
                class="label font-down text-center"
                visible
              >
                <b-icon-chevron-down></b-icon-chevron-down>
                D&eacute;tails
              </b-collapse>
            </b-col>
            <b-col cols="auto" v-if="item.pieceJointe" @click.stop>
              <DownloadComponent
                :typeDocument="typeDocument"
                :targetId="item.idMouvement"
              ></DownloadComponent>
            </b-col>
          </b-row>
          <b-collapse :id="`mouvement_${item.idMouvement}`">
            <b-row class="m-0 mt-2 font-down">
              <b-col cols="12" class="p-0">{{ item.libelleMouvement }}</b-col>
              <b-col cols="6" class="p-0">Date valeur</b-col>
              <b-col cols="6" class="p-0">{{ item.dateValeur }}</b-col>
              <b-col cols="6" class="p-0">Ech&eacute;ance</b-col>
              <b-col cols="6" class="p-0">{{ item.dateEcheance }}</b-col>
              <b-col cols="6" class="p-0">Montant TTC</b-col>
              <b-col cols="6" class="p-0">
                <MontantComponent
                  :value="item.montantTtc"
                  :options="{ style: 'currency', currency: 'EUR' }"
                  variantPositive="success"
                  variantNegative="danger"
                ></MontantComponent>
              </b-col>
              <b-col cols="6" class="p-0">Solde</b-col>
              <b-col cols="6" class="p-0">
                <MontantComponent
                  :value="item.solde"
                  :options="{ style: 'currency', currency: 'EUR' }"
                  variantPositive="success"
                  variantNegative="danger"
                ></MontantComponent>
              </b-col>
            </b-row>
          </b-collapse>
        </b-col>
      </b-row>
    </b-container>
  </b-card>
</template>

<script>
import MontantComponent from "../MontantComponent.vue";
import DownloadComponent from "../controls/DownloadComponent.vue";
export default {
  name: "MouvementFinancierCard",
  components: { MontantComponent, DownloadComponent },
  props: ["item"],
  data() {
    return {
      typeDocument: this.$store.state.expl.typesDocument.FACTURE,
    };
  },
  computed: {
    jour() {
      return this.item.dateValeur.split("-")[2];
    },
    mois() {
      return this.item.dateValeur.split("-")[1];
    },
    annee() {
      return this.item.dateValeur.split("-")[0];
    },
  },
};
</script>
