<template>
  <HelpComponent>
    <b-row class="mb-3">
      <b-col>
        Cet &eacute;cran r&eacute;capitule tous les mouvements financiers
        enregistr&eacute;s par votre coop&eacute;rative concernant votre
        exploitation. Il est parfait pour faire votre bilan comptable en fin
        d'exercice.
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col>
        Les documents (factures, bordereaux de capital social et bordereaux de
        d&eacute;caissement/encaissement) sont t&eacute;l&eacute;chargeables le
        lendemain de leur &eacute;mission par votre coop&eacute;rative.
      </b-col>
    </b-row>
    <WarningBloc>
      Ceci est un relev&eacute; comptable &agrave; lire du point de vue de la
      coop&eacute;rative :
      <ul>
        <li>
          Facture de vente = vente de la coop &agrave; votre exploitation (appro
          principalement)
        </li>
        <li>
          Encaissement = r&egrave;glement d'une somme d&ucirc;e &agrave; votre
          coop&eacute;rative (appro principalement)
        </li>
        <li>
          Facture d'achat = achat de la coop &agrave; votre exploitation
          (apport, CP, etc.)
        </li>
        <li>
          D&eacute;caissement = r&egrave;glement d'une somme d&ucirc;e &agrave;
          votre exploitation (apport, CP, etc.)
        </li>
      </ul>
    </WarningBloc>
  </HelpComponent>
</template>

<script>
import HelpComponent from "./HelpComponent.vue";
import WarningBloc from "../blocs/WarningBloc.vue";
export default {
  name: "HelpReleveCompteComponent",
  components: { HelpComponent, WarningBloc },
};
</script>
